(function () {
    'use strict';

    angular
        .module('app.product')
        .controller('ProductController', ProductController);

    ProductController.$inject = ['$http', '$state'];

    /* @ngInject */
    function ProductController($http, $state) {
        var vm = this;
        vm.scrollToFixedOptions = {
            marginTop: 80,
            unfixed: function(){
                $(this).css('position', 'static');
            },
            limit: function() {
                return $('#baseline').offset().top - $('#nav-menu').height() - 100;
            }
        };


        vm.section0 = {
            icon: 'images/talent-profile.png',
            contentIcons: [
                'images/icons/256px/Icon_visual-identities.png',
                'images/icons/256px/Icon_favorite.png',
                'images/icons/256px/Icon_site-map.png',
                'images/icons/256px/Icon_content-management.png',
                'images/icons/256px/Icon_page-quality.png',
                'images/icons/256px/Icon_copywriting.png',
                'images/icons/256px/Icon_social-engagement-81.png',
                'images/icons/256px/Icon_SEO-consultant.png'
            ]
        };
        vm.section1 = {
            icon: 'images/talent-search-engine.png',
            contentIcons: [
                'images/icons/256px/Icon_affiliate-marketing.png',
                'images/icons/256px/Icon_rocket-startup-launch-campaign-mission.png',
                'images/icons/256px/Icon_team-Social-media-marketing.png'
            ]
        };
        vm.section2 = {
            icon: 'images/matching.png',
            contentIcons: [
                'images/icons/256px/Icon_vip-presentation.png',
                'images/icons/256px/Icon_favorite-website.png',
                'images/icons/256px/Icon_blogging-chat-tree.png',
                'images/icons/256px/Icon_maintenance-service.png',
                'images/icons/256px/Icon_analysis-69.png'
            ]
        };
        vm.section3 = {
            icon: 'images/plug-and-play.png',
            contentIcons: [
                'images/icons/256px/Icon_service-tools.png',
                'images/icons/256px/Icon_users-flow.png'
            ]
        };
        vm.section4 = {
            icon: 'images/security-and-performance.png',
            contentIcons: [
                'images/icons/256px/Icon_cloud.png',
                'images/icons/256px/Icon_stopwatch--timer-performance.png',
                'images/icons/256px/Icon_WEB-optimization.png',
                'images/icons/256px/Icon_analysis-69.png'
            ]
        };
    }
})();
