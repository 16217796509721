(function () {
    'use strict';

    angular
        .module('app.widgets')
        .directive('ctAutofocus', ctAutofocus);

    ctAutofocus.$inject = ['$timeout'];

    /* @ngInject */
    function ctAutofocus ($timeout) {
        var directive = {
            link: link,
            restrict: 'A'
        };
        return directive;

        function link(scope, element, attrs) {
            $timeout(function () {
                element[0].focus();
            });
        }
    }
})();
