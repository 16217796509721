(function() {
    'use strict';

    angular.module('app.widgets', [
        'ui.bootstrap',
        'duScroll',
        'oitozero.ngSweetAlert',
        'scrollToFixed',
        'ng-drift'
    ]);

    angular.module('app.widgets').config(driftSetup);

    /* @ngInject */
    function driftSetup(ngDriftProvider) {
        ngDriftProvider.setKey('bf3fb9in543u');
        ngDriftProvider.setSnippetVersion('0.3.1');
    }
})();
