(function() {
    'use strict';

    angular
        .module('app.product')
        .directive('coreValue', coreValue);

    /* @ngInject */
    function coreValue () {
        var directive = {
            restrict: 'E',
            transclude: true,
            templateUrl: 'app/product/core-value.html',
            scope: {
                index: '<',
                section: '<',
                isLast: '<'
            }
        };

        return directive;
    }
})();
