(function () {
    'use strict';

    angular
        .module('blocks.l10n')
        .directive('languageSelector', languageSelector);

    languageSelector.$inject = ['config'];

    /* @ngInject */
    function languageSelector (config) {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/blocks/l10n/language-selector.html',
            controller: Controller
        };

        return directive;
    }


    Controller.$inject = ['$scope', 'l10n'];

    /* @ngInject */
    function Controller($scope, l10n) {
        $scope.currentLocaleDisplayName = l10n.getLocaleDisplayName();
        $scope.localesDisplayNames = l10n.getLocalesDisplayNames();
        $scope.visible = $scope.localesDisplayNames &&
            $scope.localesDisplayNames.length > 1;

        $scope.changeLanguage = function (locale) {
            l10n.setLocaleByDisplayName(locale);
        };
    }
})();
