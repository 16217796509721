(function () {
    'use strict';

    var module = angular.module('blocks.l10n');

    module.config(configure);

    configure.$inject = ['$translateProvider', 'tmhDynamicLocaleProvider'];

    /* @ngInject */
    function configure($translateProvider, tmhDynamicLocaleProvider) {
        tmhDynamicLocaleProvider.localeLocationPattern('resources/i18n/angular-locale_{{locale}}.js');

        // Do not sanitize for now (see issue: https://github.com/angular-translate/angular-translate/issues/1101)
        $translateProvider.useSanitizeValueStrategy(null);
        //$translateProvider.useSanitizeValueStrategy('sanitize');
        $translateProvider.useMissingTranslationHandlerLog();
        $translateProvider.useStaticFilesLoader({
            prefix: 'resources/l10n/locale-',// path to translations files
            suffix: '.json'// suffix, currently- extension of the translations
        });
        $translateProvider.preferredLanguage('nl');// is applied on first load
        $translateProvider.useLocalStorage();// saves selected language to localStorage
    }

})();
