(function() {
    'use strict';

    angular
        .module('app.layout')
        .directive('ctTopNav', ctTopNav);

    /* @ngInject */
    function ctTopNav () {
        var directive = {
            bindToController: true,
            link: link,
            restrict: 'E',
            templateUrl: 'app/layout/ct-top-nav.html'
        };

        return directive;

        function link(scope, element, attrs) {
            var vm = this;

            var docElem = document.documentElement,
                header = $(element).find('.primary-bar'),
                didScroll = false,
                changeHeaderOn = 90;

            function init() {
                window.addEventListener( 'scroll', function( event ) {
                    if( !didScroll ) {
                        didScroll = true;
                        setTimeout( scrollPage, 250 );
                    }
                }, false );
            }

            function scrollPage() {
                var sy = scrollY();
                if ( sy >= changeHeaderOn ) {
                    $(header).addClass('navbar-shrink navbar-fixed-top');

                }
                else {
                    $(header).removeClass('navbar-shrink navbar-fixed-top')
                }

                didScroll = false;
            }

            function scrollY() {
                return window.pageYOffset || docElem.scrollTop;
            }

            init();
        }
    }
})();
