(function() {
    'use strict';

    angular
        .module('app')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'landing.landing-page',
                config: {
                    url: '/gratis-proberen',
                    templateUrl: 'app/landing-page/landing-page.html',
                    controller: 'LandingPageController',
                    controllerAs: 'vm'
                }
            },
            {
                state: 'landing.landing-page-investors',
                config: {
                    url: '/for-investors',
                    templateUrl: 'app/landing-page/landing-page-investors.html',
                    controller: 'LandingPageInvestorsController',
                    controllerAs: 'vm'
                }
            }
        ];
    }
})();
