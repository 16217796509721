(function() {
    'use strict';

    angular
        .module('blocks.l10n')
        .factory('l10n', l10n);

    l10n.$inject = ['$log', 'toastr', '$translate', 'LOCALES', '$rootScope', 'tmhDynamicLocale', '$location'];

    /* @ngInject */
    function l10n($log, toastr, $translate, LOCALES, $rootScope, tmhDynamicLocale, $location) {
        var service = {
            data: {
                locale: null,
                language: null,
                locales: LOCALES.locales,
                localesMap: null,
                localesDisplayNames: []
            },
            guessLanguage: getLanguage,
            getLanguage: getLanguage,
            getLocaleDisplayName: function () {
                return service.data.locales[service.data.locale];
            },
            setLocaleByDisplayName: function (localeDisplayName) {
                setLocale(service.data.localesMap[service.data.localesDisplayNames.indexOf(localeDisplayName)]);
            },
            getLocalesDisplayNames: function () {
                return service.data.localesDisplayNames;
            }
        };


        service.data.localesMap = Object.keys(service.data.locales);
        if (!service.data.localesMap || service.data.localesMap.length === 0) {
            $log.error('There are no locales provided');
            throw new Error('There are no locales provided');
        }

        service.data.localesMap.forEach(function (locale) {
            service.data.localesDisplayNames.push(service.data.locales[locale]);
        });

        service.data.locale = locale || $translate.proposedLanguage() || $translate.use();
        // Set angular locale on first load
        tmhDynamicLocale.set(service.data.locale.toLowerCase().replace(/_/g, '-'));

        var checkLocaleIsValid = function (locale) {
            return service.data.localesMap.indexOf(locale) !== -1;
        };

        var setLocale = function (locale) {
            if (!checkLocaleIsValid(locale)) {
                $log.error('Locale name "' + locale + '" is invalid');
                return;
            }
            service.data.locale = locale;// updating current locale

            // asking angular-translate to load and apply proper translations
            $translate.use(locale);
        };

        $rootScope.$on('$translateChangeSuccess', function (event, data) {
            document.documentElement.setAttribute('lang', data.language);// sets "lang" attribute to html

            // asking angular-dynamic-locale to load and apply proper AngularJS $locale setting
            tmhDynamicLocale.set(data.language.toLowerCase().replace(/_/g, '-'));
        });

        var locale = $location.search().locale;
        if (locale) {
            service.setLocaleByDisplayName(locale.toUpperCase());
        }

        return service;
        /////////////////////

        function getLanguage() {
            if(!service.data.language) {
                if(!service.data.locale) {
                    service.data.locale = guessLocale();
                }
                service.data.language = service.data.locale.substring(0,2);
            }

            return service.data.language;
        }

        function guessLocale() {
            var nav = window.navigator,
                browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
                i,
                language;

            // support for HTML 5.1 "navigator.languages"
            if (Array.isArray(nav.languages)) {
                for (i = 0; i < nav.languages.length; i++) {
                    language = nav.languages[i];
                    if (language && language.length) {
                        return language;
                    }
                }
            }

            // support for other well known properties in browsers
            for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
                language = nav[browserLanguagePropertyKeys[i]];
                if (language && language.length) {
                    return language;
                }
            }

            return null;
        }


    }
}());
