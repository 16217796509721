/* global toastr:false, moment:false */
(function() {
    'use strict';

    angular
        .module('app.core')
        .constant('LOCALES', {
            'locales': {
                'nl': 'NL',
                'en': 'EN'
            },
            'preferredLocale': 'nl'
        });
})();
