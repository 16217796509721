(function () {
    'use strict';

    angular
        .module('app')
        .controller('ContactController', ContactController);

    ContactController.$inject = ['$http', '$state'];

    /* @ngInject */
    function ContactController($http, $state) {
        var vm = this;

        vm.form = {
            type: 'contact'
        };

        vm.submit = function(contactForm) {
            if (contactForm.$valid) {
                $http({
                    method  : 'POST',
                    url     : 'contact-form.php',
                    data    : $.param(vm.form),
                    headers : {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(function(response) {
                    if (response && response.data && response.data.success) {
                        $state.go('guest.contact-voltooid');
                    } else {
                        vm.submitButtonDisabled = false;
                        vm.resultMessage = data.message;
                        vm.result='bg-danger';
                    }
                });
            }
        }
    }
})();
