(function () {
    'use strict';

    var core = angular.module('app.core');

    core.config(toastrConfig);

    toastrConfig.$inject = ['toastr'];
    /* @ngInject */
    function toastrConfig(toastr) {
        toastr.options.timeOut = 4000;
        toastr.options.positionClass = 'toast-bottom-right';
    }

    var config = {
        appErrorPrefix: '[Error] ',
        appTitle: 'App'
    };

    core.value('config', config);

    core.config(configure);

    configure.$inject = ['$provide', '$logProvider', 'routerHelperProvider', 'exceptionHandlerProvider'];
    /* @ngInject */
    function configure($provide, $logProvider, routerHelperProvider, exceptionHandlerProvider) {
        if ($logProvider.debugEnabled) {
            $logProvider.debugEnabled(true);
        }
        exceptionHandlerProvider.configure(config.appErrorPrefix);
        routerHelperProvider.configure({docTitle: config.appTitle + ': '});

        $provide.decorator('$uiViewScroll', function ($delegate) {
            return function (uiViewElement) {
                 var top = uiViewElement.getBoundingClientRect().top;

                // Or some other custom behaviour...
            };
        });
    }
})();
