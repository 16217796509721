(function() {
    'use strict';

    angular
        .module('app.layout')
        .controller('ShellGuestController', ShellGuestController);

    ShellGuestController.$inject = ['$rootScope', '$timeout', '$location', 'ngDrift'];

    /* @ngInject */
    function ShellGuestController($rootScope, $timeout, $location, ngDrift) {
        var vm = this;

        ngDrift.show();
    }
})();
