(function () {
    'use strict';

    angular
        .module('app')
        .controller('LandingPageInvestorsController', LandingPageInvestorsController);

    LandingPageInvestorsController.$inject = ['$http', '$state', '$location'];

    /* @ngInject */
    function LandingPageInvestorsController($http, $state, $location) {
        var vm = this;

        vm.form = {
            type: 'trial'
        };

        vm.getUTM = function(){
            var search = $location.search();
            var utm = {};
            for (var prop in search) {
                if(search.hasOwnProperty(prop)){
                    var keys = prop.split('_');
                    if(keys.length && keys[0] === 'utm'){
                        utm[prop.toLowerCase()] = search[prop];
                    }
                }
            }
            return utm;
        };

        vm.submit = function(contactForm) {
            var data = angular.extend(vm.form, vm.getUTM());

            if (contactForm.$valid) {
                $http({
                    method  : 'POST',
                    url     : 'contact-form.php',
                    data    : $.param(data),
                    headers : {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(function(response) {
                    if (response && response.data && response.data.success) {
                        $state.go('guest.gratis-proberen-voltooid');
                    } else {
                        vm.submitButtonDisabled = false;
                        vm.resultMessage = data.message;
                        vm.result='bg-danger';
                    }
                });
            }
        }
    }
})();
